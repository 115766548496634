import { useCustomToasts } from '@systemeio/ui-shared'
import { useState } from 'react'
import { CurrencyEnum } from 'shared/enums/currency-enum'

export const useCurrency = ({ currencyChangeMessage }: { currencyChangeMessage: string }) => {
  const [currentCurrency, setCurrentCurrency] = useState<CurrencyEnum>()
  const [toastShown, setToastShown] = useState(false)
  const { infoToast } = useCustomToasts()

  const showToast = () => {
    if (toastShown) return
    infoToast(currencyChangeMessage)
    setToastShown(true)
  }

  return { currentCurrency, setCurrentCurrency, showToast }
}
