import { PaymentMethodEnum } from '@systemeio/ui-shared'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import useUser from 'shared/hooks/use-user'
import { baseFetcher } from 'shared/utils/http-client'
import useSWR from 'swr'

export function usePaymentMethods(currentCurrency?: CurrencyEnum) {
  const { user } = useUser()

  return useSWR<{
    paymentMethods: PaymentMethodEnum[]
  }>(() => {
    if (user && currentCurrency) {
      return `/api/dashboard/customer/payment-methods/${currentCurrency}`
    }
    return null
  }, baseFetcher)
}
